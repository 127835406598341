// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("C:\\wamp64\\www\\repo-github-app-generator\\gatsby-html5up-hyperspace\\node_modules\\gatsby-plugin-offline\\app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("C:\\wamp64\\www\\repo-github-app-generator\\gatsby-html5up-hyperspace\\src\\pages\\404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-elements-js": () => import("C:\\wamp64\\www\\repo-github-app-generator\\gatsby-html5up-hyperspace\\src\\pages\\elements.js" /* webpackChunkName: "component---src-pages-elements-js" */),
  "component---src-pages-generic-js": () => import("C:\\wamp64\\www\\repo-github-app-generator\\gatsby-html5up-hyperspace\\src\\pages\\generic.js" /* webpackChunkName: "component---src-pages-generic-js" */),
  "component---src-pages-index-js": () => import("C:\\wamp64\\www\\repo-github-app-generator\\gatsby-html5up-hyperspace\\src\\pages\\index.js" /* webpackChunkName: "component---src-pages-index-js" */)
}

